/* Error */
.error-page {
	min-height: 100vh;

	h1 {
		font-size: 9.375rem;
		line-height: 1;

		@media (max-width: 991px) {
			font-size: 8rem;
		}
	}

	h2 {
		font-size: 4.375rem;
		line-height: 1;
	}

	.error-page-divider {
		@media (min-width: 992px) {
			border-left: 3px solid rgba($white, .2);
		}
	}
}