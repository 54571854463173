#sales-statistics-legend {
    .chartjs-legend {
        margin-top: 20px;
        margin-right: 20px;

        ul {
            li {
                color: $body-text-color;

                span {
                    width: 20px;
                    height: 3px;
                    border-radius: 0px;
                }
            }
        }
    }
}

#net-profit-legend {
    .chartjs-legend {
        li {
            font-weight: 500;
            color: $body-text-color;
        }
    }
}

.average-price-card {
    .card {
        background: $card-accent-color;
    }

    h3 {
        margin-bottom: 0;
    }

    .icon-holder {
        border-radius: 5px;
        border: 1px solid $iconholder-primary-border-color;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $iconholder-primary-bg;
        color: $iconholder-primary-color;
        font-size: 20px;

        i {
            line-height: 1;
        }
    }
}

#dashboard-vmap {
    height: 161px;
    width: 100%;

    .jvectormap-zoomin,
    .jvectormap-zoomout {
        display: none;
    }
}