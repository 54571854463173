/* Owl-carousel */
.owl-carousel {
  img {
    border-radius: 4px;
  }

  &.full-width {
    .owl-nav {
      position: absolute;
      top: 30%;
      width: 100%;
      margin-top: 0;

      .owl-next,
      .owl-prev {
        background: transparent;
        color: $white;

        i {
          &:before {
            width: 40px;
            height: 40px;
            background: $text-muted;
            border-radius: 100%;
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 40px;
          }
        }
      }

      .owl-prev {
        float: left;
      }

      .owl-next {
        float: right;
      }
    }

    .owl-dots {
      margin-top: 1rem;
    }
  }

  .item-video {
    width: 200px;
    height: 200px;
  }
}