/* Typeahead */
.gist,
.tt-menu {
  text-align: left;
}

.twitter-typeahead {
  max-width: 100%;
}

.tt-hint,
.tt-query,
.typeahead {
  @extend .form-control;
}

.typeahead {
  background-color: $typeahead-bg;

  &:focus {
    border-color: $border-color;
  }
}

.tt-query {
  box-shadow: $tt-query-box-shadow;
}

.tt-hint {
  color: $typeahead-tt-hint-color;
}

.tt-menu {
  width: 100%;
  margin: 12px 0;
  padding: 8px 0;
  background-color: $typeahead-tt-menu;
  border: 1px solid $border-color;
  border-radius: 8px;
  box-shadow: $tt-box-shadow;
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: inherit;

  &:hover {
    cursor: pointer;
    color: $tt-suggestion-hover-color;
    background-color: $tt-suggestion-hover-bg;
  }

  .tt-cursor {
    color: $tt-suggestion-hover-color;
    background-color: $tt-suggestion-hover-bg;
  }

  p {
    margin: 0;
  }
}