/* Flot chart */
.float-chart-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 100%;
}

.float-chart {
  width: 100%;
  position: relative;

  canvas {
    position: absolute !important;
    top: 0;
    bottom: 0;
  }

  max-width: none;
  height: 400px;
}

.flot-chart-legend {
  overflow-x: auto;

  table {
    tr {
      td {
        &.legendColorBox {
          width: 20px;
          height: 20px;
          margin-right: 15px;
          padding: 0 15px 0 22px;
          @include border-radius(100%);
          overflow: hidden;

          &:first-child {
            padding-left: 0;
          }

          div {
            border-width: 0 !important;

            div {
              @include border-radius(100%);
              border-width: 5px !important;
            }
          }
        }

        &.legendLabel {
          padding: 0 25px 0 0;
          border-right: 1px solid color(gray-lightest);
          font-weight: 600;

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}