// Pagination variations
@mixin pagination-variants($color) {
  .page-item {
    &.active {
      .page-link {
        background: $color;
        border-color: $color;
      }
    }

    .page-link {
      &:hover {
        background: lighten($color,5%);
        border-color: $color;
        color: $white;
      }
    }
  }
}