/* Data Tables */

.dataTables_wrapper {
  padding-left: 0;
  padding-right: 0;

  label {
    font-size: 0.8125rem;
  }

  select {
    @extend .form-control;
    padding: 0.4rem;
    min-width: 75px;
  }

  .dataTables_length {
    select {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  .table-footer {
    padding-top: $card-padding-y;
  }

  .height-limiter {
    max-height: 400px;
    overflow-y: auto;
    box-shadow: inset 0px -6px 18px rgba(0, 0, 0, 0.06);
  }

  .dataTable {
    border-collapse: collapse !important;

    .btn {
      padding: 0.1rem 1rem;
      vertical-align: top;
    }

    thead {

      .sorting,
      .sorting_asc,
      .sorting_asc_disabled,
      .sorting_desc,
      .sorting_desc_disabled {

        &:after,
        &:before {
          top: 23px;
        }
      }

      th {
        vertical-align: middle;
        padding: $table-cell-padding;
        color: $body-color;
        font-weight: 500;
      }
    }

    tbody {
      tr {
        td {
          z-index: 10;
        }
      }
    }

    &.collapsed {
      tbody {
        >tr {

          &.odd,
          &.even {
            >td {
              &:first-child {
                padding-left: 30px;
                position: relative;

                &:before {
                  content: "\F35d";
                  font-family: "Material Design Icons";
                  font-size: 20px;
                  color: theme-color(primary);
                  text-rendering: auto;
                  line-height: inherit;
                  vertical-align: 0;
                  font-size: 30px;
                  position: absolute;
                  left: 0px;
                  top: 12px;
                }
              }
            }
          }

          &.child {
            ul.dtr-details {
              list-style: none;
              padding-left: 0;

              li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 10px;
                border-bottom: $border-color;

                .dtr-title {
                  font-weight: 500;
                }

                &:nth-child(even) {
                  background: $content-bg;
                }

                &:last-child {
                  margin-bottom: 0;
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .dataTables_paginate {
    margin-top: 20px !important;
  }

  .dataTables_info {
    font-size: $default-font-size;
    color: $text-muted;
    margin-top: 20px;
  }
}